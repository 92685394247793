import React from 'react';
import GLILPrivacyPolicyBanner from './GLILPrivacyPolicyBanner';
import GLILPrivacyPolicyContents from './GLILPrivacyPolicyContents';
import EasyLifeMobileApp from '../EasyLifePages/EasyLifeContact/EasyLifeMobileApp';
import useEasylifeTitle from '../../hooks/useEasylifeTitle';

const GLILPrivacyPolicy = () => {
    useEasylifeTitle("Privacy Policy");
    return (
        <>
            <GLILPrivacyPolicyBanner />
            <GLILPrivacyPolicyContents />
            <EasyLifeMobileApp background={"bg-white"} />
        </>
    );
};

export default GLILPrivacyPolicy;