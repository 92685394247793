import { useQuery } from '@tanstack/react-query';
import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSupport } from 'react-icons/bi';
import { BsFacebook, BsInstagram, BsYoutube } from 'react-icons/bs';
import { ImLinkedin } from 'react-icons/im';
import { Link } from 'react-router-dom';
import Guardian from '../../../assets/image/btn-myguirdian.png';
import EasyLife from '../../../assets/image/easyLife.png';
import PaymentLogo from '../../../assets/image/payment.png';
import Apex from '../../../assets/image/sponsor1.png';
import Brac from '../../../assets/image/sponsor2.png';
import Square from '../../../assets/image/sponsor3.png';
import FooterLogo from '../../../assets/logo/logo.png';
import Loading from '../../../components/Loading/Loading';
import './footer.css';

function Footer() {
    const { t } = useTranslation();
    const [banglatranslate, setBanglaTraslate] = useState();
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);
    const url = `${process.env.REACT_APP_host}/api/v1/plans`;

    const { data: plansData = [], isLoading } = useQuery({
        queryKey: ['plansData'],
        queryFn: async () => {
            try {
                const res = await fetch(url);
                const data = await res.json();
                return data?.data;
            } catch (err) {
                console.log(err);
            }
        },
    });

    if (isLoading) {
        return <Loading></Loading>;
    }

    return (
        <footer className="footer-container">
            <div className="secondary-container body-container">
                <div className="footer-grid ">
                    <div className="footer-left-logo">
                        <div> <img src={FooterLogo} alt="" /> </div>
                        <p className="text">{t('footertext')}</p>
                        <div> <img className="w-100" src={PaymentLogo} alt="" /> </div>
                    </div>
                    <div className="footer-middle">
                        <div className="footer-middle-text">
                            <h5 className="footer-items-title"> {t('footerLink')} </h5>
                            <ul className="footer-item">
                                <li className="footer-list">
                                    <Link className="footer-link" to="/about-us" > {t('aboutus')} </Link>
                                </li>
                                <li className="footer-list">
                                    <Link className="footer-link" to="/corporate-solutions" > {t('corporate')} </Link>
                                </li>
                                <li className="footer-list">
                                    <Link className="footer-link" to="/bancassurance" > {t('bancassurance')} </Link>
                                </li>
                                <li className="footer-list">
                                    <Link className="footer-link" to="/microinsurance" > {t('microinsurance')} </Link>
                                </li>
                                <li className="footer-list">
                                    <Link className="footer-link" to="/partnerchannels" > {t('easylifeADCTitle')} </Link>
                                </li>
                                <li className="footer-list">
                                    <Link className="footer-link" to="/claims"> {t('claims')} </Link>
                                </li>

                                <li className="footer-list">
                                    <Link className="footer-link" to="/career"> {t('career')} </Link>
                                </li>
                                <li className="footer-list">
                                    <Link className="footer-link" to="/preferred-hospital" > {t('hospitalList')} </Link>
                                </li>
                                <li className="footer-list">
                                    <Link className="footer-link" to="/faq"> {t('faq')} </Link>
                                </li>
                                {/* <li className="footer-list">
                                        <Link className="footer-link" to="/login"> {t("login")} </Link>
                                    </li> 
                                */}
                                <li className="footer-list">
                                    <Link className="footer-link" to="/form"> {t('support')} </Link>
                                </li>
                                <li className="footer-list">
                                    <Link className="footer-link" to="/payment"> {t('Payment channels')} </Link>
                                </li>
                                <li className="footer-list">
                                    <Link className="footer-link" to="/privacy_policy"> {t('Privacy Policy')} </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-middle-second-text">
                            <ul className="footer-item second-gird-quick">
                                <li className="footer-list">
                                    <Link to="/retail-product">
                                        <h5
                                            className="footer-link fw-bold"
                                            to=""
                                        >
                                            {t('retail')}
                                        </h5>
                                    </Link>
                                </li>
                                {plansData.map((footerPlans) => (
                                    <li
                                        key={footerPlans?._id}
                                        className="footer-list"
                                    >
                                        <Link
                                            className="footer-link"
                                            to={`/retail/${footerPlans?.slug}`}
                                        >
                                            {(banglatranslate === 'bn' &&
                                                footerPlans?.titleBn) ||
                                                footerPlans?.title}
                                        </Link>
                                    </li>
                                ))}
                                <li className="footer-list">
                                    <Link
                                        className="footer-link"
                                        to="https://retail.myguardianbd.com/tax-certificate"
                                        target="_blank"
                                    >
                                        {t('taxCertificate')}
                                    </Link>
                                </li>
                                <li className="footer-list">
                                    <Link
                                        className="footer-link"
                                        to="/notice-board"
                                    >
                                        {t('noticeboard')}
                                    </Link>
                                </li>
                                <li className="footer-list">
                                    <Link
                                        className="footer-link"
                                        to="/contact-us"
                                    >
                                        {t('Contact Us')}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className=" footer-right-logo">
                        <h5 className="footer-items-title">{t('sponsor')}</h5>
                        <div className="d-flex aling-items-center gap-4">
                            <div>
                                <img
                                    className="sponsor-logo"
                                    src={Apex}
                                    alt=""
                                />
                            </div>
                            <div>
                                <img
                                    className="sponsor-logo"
                                    src={Brac}
                                    alt=""
                                />
                            </div>
                            <div>
                                <img
                                    className="sponsor-logo"
                                    src={Square}
                                    alt=""
                                />
                            </div>
                        </div>

                        <h5 className="footer-items-title app-download">
                            {t('app')}
                        </h5>
                        <div className="d-flex aling-items-center gap-4">
                            <div className="app-img">
                                <Link
                                    to="https://play.google.com/store/apps/details?id=com.sslwireless.easylife"
                                    target="_blank"
                                >
                                    <img
                                        className="sponsor-logo"
                                        src={EasyLife}
                                        alt=""
                                    />
                                </Link>
                            </div>
                            <div className="app-img">
                                <Link
                                    to="https://play.google.com/store/apps/details?id=com.guardianlifeinsurance.myguardian"
                                    target="_blank"
                                >
                                    <img
                                        className="sponsor-logo"
                                        src={Guardian}
                                        alt=""
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="d-block d-lg-none mt-3">
                            <h5 className="footer-items-title app-download mb-3">
                                Social Link
                            </h5>
                            <div className=" d-flex align-items-center gap-4 mb-3">
                                <Link
                                    to="https://www.facebook.com/GLIL.bd/"
                                    target="_blank"
                                >
                                    <BsFacebook className="social-icon" />
                                </Link>
                                <Link
                                    to="https://www.instagram.com/guardian_life_insurance_ltd/"
                                    target="_blank"
                                >
                                    <BsInstagram className="social-icon" />
                                </Link>
                                <Link
                                    to="https://www.linkedin.com/company/guardian-life-insurance-limited/"
                                    target="_blank"
                                >
                                    <ImLinkedin className="social-icon" />
                                </Link>
                                <Link
                                    to="https://www.youtube.com/channel/UCH0hpdVbitkYbyCUhQYQiKw"
                                    target="_blank"
                                >
                                    <BsYoutube className="social-icon" />
                                </Link>
                            </div>
                        </div>
                        <div className="idra-container rounded app-download">
                            <h5 className=" d-flex align-items-center justify-content-center gap-1 footer-items-title p-2 mb-3 text-black">
                                <BiSupport className="nav-icon idra-text mt-1" />
                                <span className="idra-text">
                                    <span className="irda-content">
                                        IDRA Hotline
                                    </span>{' '}
                                    : {''}
                                    <span className="idra-number">16130</span>
                                </span>
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="footer-line">
                    <p className="copy-text">
                        <span> ©{t('copy')}</span> <span>{currentYear}</span>{' '}
                        <span>{t('copy2')}</span>
                        <Link
                            to="https://nextgenitltd.com/"
                            target="_blank"
                            className="text-white ms-1"
                        >
                            {t('development')}
                        </Link>
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
