import React, { useState } from 'react';
import './oursPlans.css';
import OurPlansTitle from './OurPlansTitle';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Breadcrumb, Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { useRef } from 'react';
import BookModal from '../../../components/BookModal/BookModal';
import { useContext } from 'react';
import { BookContext } from '../../../context/BookingContext/BookingContext';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import i18n from 'i18next';
import OurPlansAllCategories from './OurPlansAllCategories';
import Loading from '../../../components/Loading/Loading';
import { Helmet } from 'react-helmet';

function OurPlans() {
    const plans = useLoaderData();

    const navigate = useNavigate();
    const playerRef = useRef(null);
    const { handleShow } = useContext(BookContext);

    const [metaTags, setMetaTags] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [isShowMore, setIsShowMore] = useState(false);
    const { t } = useTranslation();
    const [banglatranslate, setBanglaTraslate] = useState();
    const plansData = plans?.data;

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    useEffect(() => {
        getPaginationList();
    }, [metaTags]);

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/seo?pages=${plansData?.title}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setMetaTags(data?.data?.seo);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div>
            {metaTags?.map((data) => (
                <Helmet>
                    <title>{data?.title} - Guardian Life Insurance Ltd. </title>
                    <meta name="description" content={data?.description} />
                    <meta
                        name="keywords"
                        content={data?.keywords?.map(
                            (keys) => keys?.keywordsName
                        )}
                    />
                </Helmet>
            ))}
            <div className="body-container secondary-container mt-3">
                <Breadcrumb>
                    <Breadcrumb.Item className="faq-link">
                        {t('retail')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active className="faq-link">
                        {banglatranslate === 'bn'
                            ? plansData?.titleBn
                            : plansData?.title}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="secondary-container body-container">
                <div className="plans-banner-container">
                    <div className="plans-banner-contents">
                        <div className="left-content">
                            <h1 className="title banner-title">
                                {banglatranslate === 'bn'
                                    ? plansData?.titleBn
                                    : plansData?.title}
                            </h1>
                            <p className="text banner-text">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            banglatranslate === 'bn'
                                                ? !isShowMore
                                                    ? `${plansData?.descriptionBn.slice(
                                                          0,
                                                          400
                                                      )} ...`
                                                    : plansData?.descriptionBn
                                                : !isShowMore
                                                ? `${plansData?.description.slice(
                                                      0,
                                                      400
                                                  )} ...`
                                                : plansData?.description,
                                    }}
                                ></span>
                            </p>

                            <div className="card-button">
                                <Button
                                    onClick={toggleReadMoreLess}
                                    className="btn-book btns"
                                >
                                    {isShowMore
                                        ? `${t('readless')}`
                                        : `${t('readmore')}`}
                                </Button>
                                {/* <Button
                                    className="btn-learn btns bg-transparent"
                                    onClick={handleShow}
                                >
                                    {t('letsTalk')}
                                </Button> */}
                            </div>
                        </div>
                        <div className="right-img">
                            <img src={plansData?.imageURL} alt="plans" />
                        </div>

                        
                    </div>
                </div>

                {plansData?.plansCategory?.length > 0 && (
                    <section
                        className={`${
                            (plansData?.plansCategory?.length === 4 &&
                                'plans-submenu1') ||
                            (plansData?.plansCategory?.length === 3 &&
                                'plans-submenu2') ||
                            (plansData?.plansCategory?.length === 2 &&
                                'plans-submenu3') ||
                            (plansData?.plansCategory?.length === 1 &&
                                'plan-single')
                        }`}
                    >
                        {plansData?.plansCategory?.map((categoryPlans) => (
                            <a
                                href={`#${categoryPlans?.slug}`}
                                key={categoryPlans?._id}
                                className="default-submenu d-flex justify-content-center align-items-center"
                            >
                                {banglatranslate === 'bn'
                                    ? categoryPlans?.categoryTitleBn
                                    : categoryPlans?.categoryTitle}
                            </a>
                        ))}
                    </section>
                )}

                <div>
                    {plansData?.plansCategory?.length > 0 && (
                        <OurPlansTitle
                            title={
                                banglatranslate === 'bn'
                                    ? plansData?.titleBn
                                    : plansData?.title
                            }
                        />
                    )}
                </div>
                <div
                    className={
                        plansData?.plansCategory?.length > 0
                            ? 'plans-container-margin'
                            : 'mt-0'
                    }
                >
                    {plansData?.plansCategory?.map((bannerItem, i) => (
                        <div key={i}>
                            <OurPlansAllCategories
                                bannerItem={bannerItem}
                                i={i}
                                t={t}
                                banglatranslate={banglatranslate}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {plansData?.vedioLink && (
                <div className="product-vedio-container">
                    <div className="secondary-container body-container">
                        <h1 className="title product-vedio-title">
                            {t('productVedio')}
                        </h1>
                        <p className="text-center text  blog-text">
                            {t('productVrdioDesc')}
                        </p>

                        <div className="vedio-player-container shadow">
                            <ReactPlayer
                                className="vedio-size"
                                ref={playerRef}
                                url={plansData?.vedioLink}
                                controls={true}
                            />
                        </div>
                    </div>
                </div>
            )}

            <BookModal />
        </div>
    );
}

export default OurPlans;
