import React from "react";
import "./CorporateSolutions.css";
import CorporateBanner from "./CorporateBanner";
import CorporateSubmenu from "./CorporateSubmenu";
import CorporatePartnerSection from "./CorporatePartnerSection";
import CorporateBookNow from "./CorporateBookNow";
import PartnerClient from "../Home/PartnerClient/PartnerClient";
import useTitle from "../../hooks/useTitle";
import CorporateSolutionsNewClientLogo from "./CorporateSolutionsNewClientLogo";
import Loading from "../../components/Loading/Loading";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

function CorporateSolutions() {
  const [metaTags, setMetaTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getPaginationList();
  }, [metaTags]);

  function getPaginationList() {
    const url = `${process.env.REACT_APP_host}/api/v1/seo?pages=Corporate`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setMetaTags(data?.data?.seo);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }
  // useTitle("Corporate Solution");
  return (
    <div>
      {metaTags?.map((data) => (
        <Helmet>
          <title>{data?.title} - Guardian Life Insurance Ltd. </title>
          <meta name="description" content={data?.description} />
          <meta
            name="keywords"
            content={data?.keywords?.map((keys) => keys?.keywordsName)}
          />
        </Helmet>
      ))}
      <CorporateBanner />
      <CorporateSubmenu />
      {/* <CorporateBookNow /> */}
      {/* <CorporatePartnerSection /> */}
      {/* <PartnerClient /> */}
      <CorporateSolutionsNewClientLogo />
    </div>
  );
}

export default CorporateSolutions;
