import React from "react";
import CareerBanner from "../CareerBanner/CareerBanner";
import CareerDetails from "../CareerDetails/CareerDetails";
import useTitle from "../../../hooks/useTitle";

function Career() {
  useTitle("Career");
  return (
    <>
      <CareerBanner />
      {/* <CareerDetails /> */}
    </>
  );
}

export default Career;
