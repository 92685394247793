import React from 'react';

const privacyPolicies = [
    {
        id: 1,
        title: "Data",
        details: "The type of personal data we collect depends upon the circumstances in which that data is collected. We may collect details about prospective and existing customers including name; National identity card number, passport number, date of birth, telephone number, postal address, email address, bio-data and health information, employment information, financial information, investment portfolios, the same personal data relating to any other relevant individuals (e.g. family members or beneficiaries) to enable the provision of insured benefits, and other personal data relevant to the insurance being applied for."
    },
    {
        id: 2,
        title: "PURPOSE",
        details: "<p style='margin-bottom: 0.5rem !important;'>We collect personal data as required by our business purposes including, but not limited to: </p> <ul className='d-flex flex-column gap-1' style='margin-left: 48px !important;'><li style='list-style: disc !important;'>Assessing, evaluating and processing applications for insurance;</li><li style='list-style: disc !important;'>Administering an insurance policy (including a group policy) and providing services in relation to it;</li><li style='list-style: disc !important;'>Assessing and investigating claims made under an insurance policy, and processing and paying claims under it;</li><li style='list-style: disc !important;'>Contacting you regarding enquiries, the collection of insurance premium, payment discrepancies, renewals, changes to an insurance policy mid-term or any other matter relating to an insurance policy or a claim under it;</li><li style='list-style: disc !important;'>Underwriting and reinsuring risk;</li><li style='list-style: disc !important;'>Verifying identities;</li><li style='list-style: disc !important;'>For our own internal records;</li><li style='list-style: disc !important;'>Marketing (please see below for more information);</li><li style='list-style: disc !important;'>Commencing or defending any legal proceedings brought against us, our Directors, Executives, Officers, Employees, Subcontractors, Agents, Affiliates and Subsidiaries;</li><li style='list-style: disc !important;'>Compliance with applicable laws, regulations (ie IDRA) or any industry codes or guidelines; and</li><li style='list-style: disc !important;'>For other ancillary purposes which are directly related to the above purposes.</li></ul>"
    },
    {
        id: 3,
        title: "PROVISION OF DATA",
        details: "We will let you know whether it is obligatory or voluntary to supply your personal data when we ask you for it. In most cases, it will be obligatory. We will also explain to you the consequences of failing to supply the data we ask for; the purpose for which the data is being collected; persons to whom the data may be transferred; your rights to request access to and correct your data, and the contact details of the Data Protection Officer who will handle these requests."
    },
    {
        id: 4,
        title: "COLLECTION",
        details: "We ensure that we will not collect data beyond what we require for our business activities. We ensure that we collect data by lawful and fair means in compliance with applicable data protection legislation of the nation."
    },
    {
        id: 5,
        title: "CONSENT",
        details: "We will obtain your consent before using, collecting or disclosing your personal data for any purpose and will notify you of your right to withdraw consent at any time and the consequences of doing so."
    },
    {
        id: 6,
        title: "USAGE",
        details: "The purposes for which personal data will be used will depend on the circumstances in which that personal data is collected. Details of the purposes for which we use personal data are contained in our Personal Information Collection Statement which we will issue to you at or before the time we collect your personal data. Genera Ily, we may use personal data for the purpose for which it was provided to us, purposes which are directly related to the purpose for which it was provided to us, and any other purpose to which you have consented."
    },
    {
        id: 7,
        title: "ACCURACY",
        details: "We will make a reasonable effort to accurately record your personal data in our systems. This will include reviewing the completeness of any submitted forms and/or other written documentation, and verifying identities and particulars against identification documents. We may assume that personal data collected directly is accurate and complete and we may ask you for a verbal or written declaration that the personal data provided to us is accurate and complete. In certain situations, we may request that personal data is provided again to ensure that records are current and we will make a reasonable effort to accurately record the updated personal data provided in our systemsWe will make a reasonable effort to accurately record your personal data in our systems. This will include reviewing the completeness of any submitted forms and/or other written documentation, and verifying identities and particulars against identification documents."
    },
    {
        id: 8,
        title: "CONFIDENTIALITY",
        details: "We are committed to protecting the confidentiality and security of personal data. We will comply with applicable data protection requirements and will only collect, use or disclose personal data in accordance with applicable data protection laws. Personal data may be shared with third parties, such as Reinsurers. Service providers (e.g., IT services, legal services), Regulators and law enforcement authorities, Business partners, but only when necessary for the fulfillment of insurance services. All third parties are required to comply with our data protection policies and are subject to contractual agreements to ensure the security and confidentiality of personal data. We may use policy information of real cases for our training purposes internally. In these circumstances, we will not share any personal data that will enable you to be identified."
    },
    {
        id: 9,
        title: "MARKETING",
        details: "Where we need to disclose information for a purpose for which have not consented, we will first ask you for your consent. We will inform you of your options to receive or opt-out of direct marketing communications from us and/or other companies."
    },
    {
        id: 10,
        title: "MEDICAL INFORMATION",
        details: "We will keep all medical information confidential. It will only be disclosed to those involved with your treatment or care, including your General Practitioner/Primary Health Physician, or to their agents."
    },
    {
        id: 11,
        title: "RETENTION",
        details: "We will keep personal data for no longer than is necessary depending on the circumstances and type of personal data. For more details about our retention policies, please see our Retention Statement."
    },
    {
        id: 12,
        title: "SECURITY",
        details: "We will take all practicable steps to ensure that personal data is protected against unauthorized access, processing, erasure, loss, collection, use, disclosure, copying, modification, disposal and other similar risks. We have in place appropriate physical, electronic and managerial measures to safeguard and secure] personal data from unauthorized access. However complete confidentiality and security is not guaranteed, especially in relation to electronically held information. We do not provide any warranty against third parties' unauthorized access to personal data. We are not responsible in any manner for direct, indirect, special or consequential damages, howsoever caused, arising out of the provision of personal data to us."
    },
    {
        id: 13,
        title: "DATA ACCESS",
        details: "You may request a copy of the personal data which we hold about you and information about the ways personal data has been or may have been used or disclosed in the past year by us. You may also request correction of your personal data through online ticketing system. The protection of personal data, we will need to verify identities before processing a request. <br><br>Data may not be provided where it could reasonably be expected to reveal the identity of someone whose consent to the disclosure of their identity has not been given.<br><br>If the data provided by the customer does not match the information on their ID (e.g., Smart Card, NID, Passport), GLIL will use the data from the ID unless the customer provides a valid justification for the discrepancy along with supporting documents. This policy should be explicitly disclosed."
    },
]


const GLILPrivacyPolicyContents = () => {
    return (
        <div className='service-container'>
            <div className='body-container secondary-container'>
                <div className='d-flex flex-column gap-5'>
                    {
                        privacyPolicies?.map(policy =>
                            <section key={policy}>
                                <div class="d-flex align-items-center gap-4">
                                    <div class="title banner-title">{policy?.title}</div>
                                </div>
                                <div className='mt-4'>
                                    <p class="message-text">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    policy?.details
                                            }}
                                        ></span>
                                    </p>
                                </div>
                            </section>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default GLILPrivacyPolicyContents;