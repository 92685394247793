import React from 'react';
import BgBannerImg from "../../assets/BG/bgLeft.png";
import BannerImg from "../../assets/easylife/banner/banner-9.jpg";
import { Link } from 'react-router-dom'; 

const BgStyle = {
    backgroundImage: `url(${BgBannerImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
};

const bannerItems = [
    {
        id: 1,
        title: `Privacy Policy`,
        desc: `When we refer to "us", "we," "our," or "GLIL" in this Privacy Policy, we are referring to Guardian Life Insurance Limited. This Privacy Policy describes the kind of personal data we collect, the purposes for which we collect and hold personal data, and our usage of personal data. It also describes the policies and practices we maintain in relation to privacy and personal data. We may amend this Privacy Policy at any time and for any reason. `,
        img: BannerImg,
        link: `/privacy_policy`
    },
];

function GLILPrivacyPolicyBanner() {
    return (
        <div style={BgStyle}>
            <div className="body-container secondary-container banner-container">
                {bannerItems.map((bannerItem) => (
                    <div key={bannerItem.id} className="banner-content">
                        <div className="left-content">
                            <h1 className="title banner-title">{bannerItem?.title}</h1>
                            <p className="text banner-text">
                                {bannerItem?.desc}<br />
                                The up-to-date version is available by following the following link — 
                                <Link to={bannerItem?.link} target="_blank">{`${window.location.origin}${bannerItem?.link}`}</Link>
                            </p>
                            
                        </div>
                        <div className="right-img">
                            <img src={bannerItem?.img} alt="" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default GLILPrivacyPolicyBanner