import React, { useEffect } from "react";
import "./chairmanMeassage.css";
import OurPlansTitle from "../../CatagoryPlans/OurPlans/OurPlansTitle";
import signature from "../../../assets/image/charmainSignature.png";
import chairmanImg from "../../../assets/image/chairman.jpeg";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const messageTexts = [
  {
    id: 1,
    text: `Guardian Life Insurance Limited is here to radically change the traditional approach to Life Insurance, injecting new energy, vitality and professionalism into our relationship with our potential customers. We wish to establish new paradigm of commitment, professionalism, integrity, and dignity in the provision of security for the future of our client and their loved ones. This is our firm commitment. The customers will be the principal stakeholders of Guardian, members of the Guardian Life Insurance Limited. As you know, the chief sponsors of this company, namely BRAC, Square and Apex are market leaders in their own fields. These giants of the industry have long grown undeniable brand heritage. Besides, the company has 3 (three) foreign and 2 (two) local private acute investors.`,
    text2: `All of these companies are renowned for their good governance and it is only fitting that a life insurance company being sponsored by them will walk in the same direction. Guardian Life also has the unique vision of spreading the benefits of insurance to all the sectors of the society, and that is why the company is introducing three different category products for three different target markets. The company recognizes social development as a core bottom line target and is striving to achieve the tag of a corporate social citizen, a movement that will improve the industry for the better. `,
    text3: ` The little sapling we planted today with all of your blessings will become the giant tree under whose shade the Guardian family will find the strength and courage to meet every adversity with the insurance of our constant support.`,
    // img: signature,
    name: "Mr. Samuel S Chowdhury",
    designation: "CHAIRMAN",
    mobile: "+8809612016622 ",
    email: "samuel@guardianlife.com.bd",
  },
];

function MessageText() {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const messageTexts = [
    {
      id: 1,
      text: `${t("chairmanMessageText1")}`,
      text2: `${t("chairmanMessageText2")}`,
      text3: `${t("chairmanMessageText3")}`,
      // img: signature,
      name: `${t("chairmanName")}`,
      designation: `${t("chairmanDesignation")}`,
      mobile: "+8809612016622 ",
      email: "samuel@guardianlife.com.bd",
    },
  ];
  return (
    <div className="body-container secondary-container message-contaner banner-container">
      <div>
        {/* <OurPlansTitle title="The message" /> */}
        <h1 class=" title text-center list-titles">
          {t("chairmanMessageTitle")}
        </h1>
      </div>
      {messageTexts.map((messageTextContent) => (
        <>
          <div className="message-contents">
            <div className="d-flex gap-5 flex-column-reverse flex-md-row">
              <div className="col-12 col-md-7">
                <p
                  className="message-text mt-4 mt-md-0"
                  style={{ textAlign: "justify" }}
                >
                  {messageTextContent?.text}
                </p>
              </div>
              <div className="col-12 col-md-4 right-img">
                <img src={chairmanImg} alt="" />
              </div>
            </div>
            <p className="message-text mt-4" style={{ textAlign: "justify" }}>
              {messageTextContent?.text2}
            </p>
            <p className="message-text mt-4" style={{ textAlign: "justify" }}>
              {messageTextContent?.text3}
            </p>
          </div>
          <div>
            {/* <img
              className="signature-img"
              src={messageTextContent?.img}
              alt="signature"
            /> */}
            <h1 className="name-title">{messageTextContent?.name}</h1>
            <h6 className="designation">{messageTextContent?.designation}</h6>
            <h6 className="phoneEmail">Phone: {messageTextContent?.mobile}</h6>
            <h6 className="phoneEmail">Email: {messageTextContent?.email}</h6>
          </div>
        </>
      ))}
    </div>
  );
}

export default MessageText;
